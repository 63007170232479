<template>
  <div class="flex p-4 pb-8 rounded bg-grey-lightest">
    <div class="w-full">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters') }}
      </h3>
      <div>
        <span class="text-xs block mb-2">{{ $t('fields.country') }}</span>
        <el-select
          :value="filters.country"
          :disabled="loading"
          :placeholder="$t('validations.select', { value: $t('fields.country')})"
          size="small"
          class="w-full"
          filterable
          @change="v => $emit('update-filter', { country: v })"
        >
          <el-option
            v-for="country in [{ CountryName: $t('pricing.all_countries'), CountryISO2alpha: '' }, ...countries]"
            :key="country.CountryISO2alpha"
            :label="country.CountryName"
            :value="country.CountryISO2alpha"
          />
        </el-select>
      </div>
      <div class="mt-5">
        <span class="text-xs block mb-2">{{ $t('fields.subaccount') }}</span>
        <el-select
          :value="filters.subAccountId"
          :disabled="loading"
          :loading="false"
          size="small"
          class="w-full"
          :placeholder="$t('validations.select', { value: $t('fields.subaccount')})"
          filterable
          @change="v => $emit('update-filter', { subAccountId: v })"
        >
          <el-option
            v-for="subaccount in subaccountList"
            :key="subaccount.SubAccountUid"
            :label="subaccount.SubAccountId"
            :value="subaccount.SubAccountUid"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    defaultCountry: {
      type: String,
      default: '',
    },

    countries: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
