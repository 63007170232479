/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    loading: true,
    subaccounts: [],
    timezones: [],
    hasSmsAccess: false,
    hasChatAppsAccess: false,
    hasSmsEngageAccess: false,
    hasVoiceAccess: false,
  },

  getters: {
    getLoading: st => st.loading,
    getUser: st => st.user,
    getSubaccounts: st => st.subaccounts,
    getTimezones: st => st.timezones,
    getSmsAccess: st => st.hasSmsAccess,
    getChatAppsAccess: st => st.hasChatAppsAccess,
    getSmsEngageAccess: st => st.hasSmsEngageAccess,
    getVoiceAccess: st => st.hasVoiceAccess,
  },

  mutations: {
    SET_LOADING: (st, payload) => { st.loading = payload; },

    SET_SUBACCOUNTS: (st, payload) => { st.subaccounts = payload.data; },

    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload.user;

      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
      } = payload.user;

      state.hasSmsAccess = hasSMSAccess;

      state.hasChatAppsAccess = hasCAAccess;

      state.hasVoiceAccess = hasVoiceAccess && betaFeatures.includes('VO-Messaging');

      state.hasSmsEngageAccess = hasSMSAccess
        && hasSurvey && hasSurveyForms;
    },

    SET_TIMEZONES(state, payload) {
      const timezones = payload.reduce((acc, tz) => {
        const offset = new Date(tz.GMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (tz.GMTOffset > 0) {
          prefix = '+';
        }
        tz.Combined = `(UTC${prefix}${offset}): ${tz.TimeZoneName}`; // eslint-disable-line
        acc.push(tz);

        return acc;
      }, []);

      state.timezones = timezones; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};
