import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
// import locale from 'element-ui/lib/locale/lang/en';
// import singleSpa from 'single-spa';
import { sync } from 'vuex-router-sync';

import ErrorUIHandler from '../common/handle-error-msg';

import App from './App.vue';
import routes from './router';
import store from './store';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE'), // set locale
  fallbackLocale: 'en',
});

Vue.use(VueRouter);

const router = new VueRouter({
  base: '/pricing',
  mode: 'history',
  routes,
});

sync(store, router);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#pricing',
    i18n,
    store,
    router,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });


      const {
        localeMessages, langUtil, telemetry,
      } = this;

      Vue.prototype.$telemetry = telemetry;

      Vue.use(ErrorUIHandler);


      return h(App, { props: { localeMessages, langUtil } });
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
