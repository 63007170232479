import fileSaver from 'file-saver';
import http from '../../../../utils/http';

export default {
  // Fetch pricings data
  getCAPricings: async ({ commit }, payload = {}) => {
    const query = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data } = await http.v2.get(`chats/channels/pricing?${query}`);
      const sortedData = data.sort((a, b) => {
        if (a.CountryName < b.CountryName) { return -1; }
        if (a.CountryName > b.CountryName) { return 1; }
        return 0;
      });

      commit({
        type: 'SET_PRICING',
        pricing: sortedData,
        app: 'chatapp',
      });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  getSMSPricings: async ({ commit, state }) => {
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data } = await http.v1.get(`payments/pricing?country=${state.filters.country}`);
      commit({
        type: 'SET_PRICING',
        pricing: data,
        app: 'sms',
      });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  getStaticPriceListDownloadUrl: async ({ commit }, payload = {}) => {
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data } = await http.v2.get(`stigg/accounts/${payload.accountId}/static-price-list?key=Pricing/8x8_Proactive_Outreach-Messages_List_Price.pdf`);

      return data.location;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  // Download sms pricing
  downloadSmsPricing: async ({ commit }) => {
    commit({ type: 'SET_DOWNLOAD_LOADING', loading: true });
    try {
      const { data } = await http.v1.get('pricing/download');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

      return fileSaver.saveAs(blob, 'sms-pricing-on-all-countries.csv');
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_DOWNLOAD_LOADING', loading: false });
    }
  },

  // Download chat pricings
  downloadChatPricing: async ({ commit, state }) => {
    commit({ type: 'SET_DOWNLOAD_LOADING', loading: true });
    try {
      const columnKeys = Object.keys(state.CAPricing[0]);

      // Add each item into a string which represents the data for each row
      const content = state.CAPricing.reduce((text, item) => {
        const rowItem = Object.values(item).toString();
        return `${text} ${rowItem} \n`;
      }, '');

      // Insert columnKeys on top of the string as table headers
      const data = `${columnKeys.toString()} \n ${content}`;

      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      return fileSaver.saveAs(blob, 'chat-pricing-on-all-countries.csv');
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_DOWNLOAD_LOADING', loading: false });
    }
  },

  getPricingCountries: async ({ commit }) => {
    commit({ type: 'SET_LOADING', loading: true });

    try {
      // VUE_APP_CP_API_URL
      const { data } = await http.v1.get('pricing/countries');
      commit({
        type: 'SET_COUNTRIES',
        countries: data,
      });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

};
