<template>
  <el-container
    class="h-full flex">
    <div class="h-full w-full flex">
      <el-main
        class="p-0 bg-white h-full"
        style="padding: 0;">
        <section class="h-full main__section">
          <RouterView />
        </section>
      </el-main>
    </div>
  </el-container>
</template>

<script>
export default {
  name: 'layout',
};
</script>
