<template>
  <div class="mb-20">
    <div class="flex justify-between items-center">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
        {{ $t('sidebar_menu[8]') }}
      </h1>
      <span
        v-if="isSubscriptionActive()"
        class="text-xs cursor-pointer font-semibold text-blue-dark flex items-center no-underline"
        @click="downloadStaticPricing()"
      >
        <i class="icon-novo-download text-base mr-1" />
        <span>{{ $t('pricing.download_price_list') }}</span>
      </span>
    </div>
    <el-tabs
      v-model="activeTabName"
      class="mb-2"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        v-for="app in Object.keys(appList)"
        :key="app"
        :label="appList[app].label"
        :name="app"
        :disabled="appList[app].disabled"
      />
    </el-tabs>
    <div class="flex justify-between mb-5 items-center">
      <span class="text-sm text-grey block">
        {{ $t('pricing.home[0]') }}
      </span>
    </div>
    <div class="flex -mx-3">
      <div
        class="px-3 w-full"
      >
        <div class="w-full">
          <!-- SMS -->
          <SmsTable
            v-show="activeTabName === 'sms' && smsAccess"
            :loading="loading"
            :filters="filters"
            :subaccount-list="subaccountList"
            :data="getPaginatedAppData()"
          />
          <!-- CHAT APP -->
          <ChatTable
            v-show="activeTabName === 'chatapps' && chatAccess"
            :search="search"
            :loading="loading || initLoading"
            :data="getPaginatedAppData()"
          />
          <Pagination
            v-if="getAppData().length"
            :index="pagination.index"
            :data="getAppData()"
            :limit="pagination.limit"
            :loading="loading || initLoading"
            @prev-page="prevPage"
            @next-page="nextPage"
          />
        </div>
      </div>
      <div class="main__aside px-3">
        <div class="pt-20 -mt-20 sticky pin-t">
          <SmsFilter
            v-if="activeTabName === 'sms' && smsAccess"
            :loading="loading"
            :default-country="tzCountry"
            :countries="countries || []"
            :filters="filters"
            :search="search"
            :subaccount-list="subaccountList"
            @update-filter="updateFilter"
          />
          <ChatFilter
            v-else-if="activeTabName === 'chatapps' && chatAccess"
            :loading="loading"
            :default-country="tzCountry"
            :countries="countries || []"
            :filters="filters"
            :search="search"
            :subaccount-list="subaccountList"
            @update-filter="updateFilter"
          />
          <div class="mt-5 shadow p-5 rounded flex flex-col justify-center">
            <p class="text-sm text-black text-center">
              <!-- Click the button below to download {{ appList[activeTabName].label }} pricing document -->
              {{ $t('pricing.home[1]', { label: appList[activeTabName].label}) }}
            </p>
            <el-button
              :loading="loadingDownload"
              :disabled="loading"
              class="mt-2"
              size="small"
              type="primary"
              @click="exportData"
            >
              {{ $t('actions.export') }} {{ appList[activeTabName].label }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import ChatTable from './partials/ChatTable.vue';
import SmsTable from './partials/SmsTable.vue';
import Pagination from './partials/Pagination.vue';
import ChatFilter from './partials/ChatFilter.vue';
import SmsFilter from './partials/SmsFilter.vue';
import { getSubscribedCountry } from '../../../common/handle-package-limit';

export default {
  name: 'Pricing',

  components: {
    ChatTable,
    SmsTable,
    Pagination,
    ChatFilter,
    SmsFilter,
  },

  data() {
    return {
      appList: {},

      search: '',

      activeTabName: 'sms', // Show sms pricing by default

      initLoading: true,

      tzCountry: '',
    };
  },

  computed: {
    ...mapGetters({
      loading: 'pricing/getLoading',
      loadingDownload: 'pricing/getDownloadLoading',
      loadingSubaccount: 'user/getLoading',
      filters: 'pricing/getFilters',
      subaccountList: 'user/getSubaccounts',
      smsPricing: 'pricing/getSMSPricing',
      chatPricing: 'pricing/getCAPricing',
      countries: 'pricing/getCountries',
      timezones: 'user/getTimezones',
      pagination: 'pricing/getPagination',
      smsAccess: 'user/getSmsAccess',
      chatAccess: 'user/getChatAppsAccess',
      hasPricingEntitlementAccess: 'pricing/getPricingEntitlementAccess',
      user: 'user/getUser',
    }),
  },

  created() {
    // Set tab name and accessibility
    const self = this;
    this.appList = {
      sms: {
        label: self.$t('sidebar_menu_parent.SMS'),
        disabled: !this.smsAccess,
      },
      chatapps: {
        label: self.$t('sidebar_menu_parent.Chat apps'),
        disabled: !this.chatAccess,
      },
    };


    // Load initial data
    this.loadSelectOptions();
  },

  methods: {
    ...mapMutations({
      setFilters: 'pricing/SET_FILTERS',
      setPagination: 'pricing/SET_PAGINATION',
    }),

    ...mapActions({
      getCAPricing: 'pricing/getCAPricings',
      getSMSPricing: 'pricing/getSMSPricings',
      getStaticPricingDownloadUrl: 'pricing/getStaticPriceListDownloadUrl',
      downloadChatPricing: 'pricing/downloadChatPricing',
      downloadSmsPricing: 'pricing/downloadSmsPricing',
      getSmsSubaccounts: 'user/fetchSMSSubAccounts',
      getChatSubaccounts: 'user/fetchCASubAccounts',
      getCountries: 'pricing/getPricingCountries',
      getTimezones: 'user/getTimezones',
    }),

    isSubscriptionActive() {
      const { Subscription: { Package: { subscriptions = {} } = {} } = {} } = this.user;
      const { status } = subscriptions[0] || {};

      return status === 'ACTIVE';
    },

    async downloadStaticPricing() {
      const url = await this.getStaticPricingDownloadUrl({ accountId: this.user.AccountId });

      const newWindow = window.open('', '_blank');
      newWindow.location.href = url;
    },

    handleTabClick({ name }) {
      let initFilter = {};

      const subAccountId = _.get(this, 'filters.subAccountId', []);
      const subAccountUid = _.get(this, 'subaccountList[0].SubAccountUid');

      let defaultSubaccount;

      switch (name) {
      case 'sms':
        // Set default filters for SMS
        initFilter = {
          ...this.filters,
          country: this.filters.country || this.tzCountry,
          subAccountId: [],
        };

        this.updateFilter(initFilter);
        break;

      case 'chatapps':

        if (subAccountId.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          defaultSubaccount = this.filters.subAccountId[0];
        } else if (subAccountUid) {
          defaultSubaccount = subAccountUid;
        }


        initFilter = {
          ...this.filters,
          country: '',
          subAccountId: defaultSubaccount,
        };

        this.updateFilter(initFilter);
        break;

      default:
        break;
      }
    },

    nextPage() {
      this.setPagination({
        index: this.pagination.index + 1,
      });
    },

    prevPage() {
      this.setPagination({
        index: this.pagination.index - 1,
      });
    },

    // Update filter state
    updateFilter(filter = {}) {
      this.setPagination({
        index: 0,
      });
      this.setFilters({
        filters: {
          ...this.filters,
          ...filter,
        },
      });

      this.getData();
    },

    // Fetch pricing data
    async getData() {
      this.initLoading = true;
      try {
        const payload = this.filters;
        if (this.activeTabName === 'chatapps' && this.chatAccess) {
          await this.getChatSubaccounts();
          if (this.subaccountList.length <= 0) return;
          await this.getCAPricing(payload);
        } else if (this.activeTabName === 'sms' && this.smsAccess) {
          await this.getSmsSubaccounts();
          if (this.subaccountList.length <= 0) return;
          await this.getSMSPricing();
        }

        this.index = 0;
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.initLoading = false;
      }
    },

    async loadSelectOptions() {
      try {
        await this.getCountries();
        await this.getTimezones();

        const tzname = this.$jstz2.determine().name();
        this.tzCountry = this.timezones.find(tz => tz.TimeZoneName === tzname).Country;

        // Set initial filter
        this.updateFilter({
          ...this.filters,
          country: this.tzCountry,
        });
      } catch (err) {
        this.$showError(this, err);
      }
    },

    exportData() {
      switch (this.activeTabName) {
      case 'sms':
        this.downloadSmsPricing()
          .catch((e) => {
            this.$showError(this, e);
          });
        break;
      case 'chatapps':
        this.downloadChatPricing().catch((e) => {
          this.$showError(this, e);
        });
        break;
      default:
        break;
      }
    },

    getPaginatedAppData() {
      let data = [];
      switch (this.activeTabName) {
      case 'sms':
        data = [...this.smsPricing];
        break;
      case 'chatapps':
        data = [...this.chatPricing];
        break;
      default:
        break;
      }

      const {
        index,
        limit,
      } = this.pagination;

      return data.slice((index) * limit, (index + 1) * limit);
    },

    getAppData() {
      let data = [];
      switch (this.activeTabName) {
      case 'sms':
        data = [...this.smsPricing];
        break;
      case 'chatapps':
        data = [...this.chatPricing];
        break;
      default:
        break;
      }

      return data;
    },
  },
};
</script>
