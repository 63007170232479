//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handlePackageLimit } from '../../../../common/handle-package-limit';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      type: Object,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      subaccountsMap: {},
    };
  },

  watch: {
    subaccountList() {
      this.generateSubaccountMap();
    },
  },

  created() {
    this.generateSubaccountMap();
  },

  methods: {
    generateSubaccountMap() {
      // create a subaccount dictionary
      this.subaccountsMap = this.subaccountList.reduce((list, item) => {
        // eslint-disable-next-line no-param-reassign
        list[item.SubAccountUid] = item.SubAccountId;
        return list;
      }, {});
    },

    // returns an array of subaccountId to be shown in the column
    getViewableSubaccounts() {
      if (!this.data.length) return [];

      const { subAccountId } = this.filters;
      let subaccounts = [];

      if (subAccountId.length) {
        subaccounts = subAccountId.map(subacc => this.subaccountsMap[subacc]);
      } else {
        subaccounts = Object.keys(this.data[0].SubAccounts || []);
      }

      return subaccounts;
    },

    showColumn(column) {
      let show = true;
      let user;
      user = localStorage.getItem('CPV3_User') || {};
      user = user && JSON.parse(user);

      if (user.Subscription.Status && handlePackageLimit(column)) {
        show = false;
      }

      return show;
    }
  },
};
