import http from '../../../../utils/http';


export default {
  // Fetch subaccounts list
  async fetchSMSSubAccounts({ commit }) {
    commit({ type: 'SET_LOADING', payload: true });
    try {
      const { data } = await http.v2.get('mcs/sub-accounts/sms');

      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  async fetchCASubAccounts({ commit }) {
    commit({ type: 'SET_LOADING', payload: true });
    try {
      const { data } = await http.v2.get('chats/sub_accounts');
      commit({ type: 'SET_SUBACCOUNTS', data });

      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  async fetchVoiceSubAccounts({ commit }) {
    commit({ type: 'SET_LOADING', payload: true });
    try {
      const { data } = await http.v2.get('voice/sub-accounts/details');
      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  async fetchSMSEngageSubAccounts({ commit }) {
    commit({ type: 'SET_LOADING', payload: true });
    try {
      const { data } = await http.v2.get('mcs/sms-engage/sub-accounts');
      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  // Fetch timezones
  async getTimezones({ commit }) {
    commit({ type: 'SET_LOADING', payload: true });
    try {
      const { data } = await http.v1.get('timezones');
      commit('SET_TIMEZONES', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  // Fetch user info
  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};

      const userData = JSON.parse(cpv3User);
      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });

      return user;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return err;
    }
  },
};
