//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'App',

  data() {
    return {};
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  created() {
    this.getUser()
      .catch((e) => {
        this.$showError(this, e);
      });
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },

  methods: {
    ...mapActions({
      getUser: 'user/getUser',
    }),
  },
};
