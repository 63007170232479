<template>
  <!-- <div> -->
  <!--   <code class="whitespace&#45;pre&#45;wrap text&#45;sm"> -->
  <!--     {{ data }} -->
  <!--   </code> -->
  <!-- </div> -->
  <div>
    <div
      class="mt-2 mb-5 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded w-full leading-normal break-words-p"
    >
    Effective June 1, 2024, WhatsApp introduces new “Authentication-International” rates for authentication messages in specific countries. This update affects businesses sending authentication messages to users in those countries. Click <a
        href="https://www.8x8.com/cpaas/blog/whatsApp-authentication-rates-Indonesia-India"
        class="text-blue"
        target="_blank"
        rel="noopener nofollow"
      > here</a> to learn more.
    </div>
    <el-table
      v-loading="loading"
      size="small"
      class="w-full ca-table"
      :data="data"
      fit
    >
      <!-- Country column -->
      <el-table-column
        v-slot="scope"
        :label="$t('fields.country')"
        prop="country"
        fixed
        show-overflow-tooltip
      >
        <div class="text-xs truncate">
          {{ scope.row.CountryName }}
        </div>
      </el-table-column>

      <el-table-column class="bg-black text-white">
        <!-- WhatsApp column -->
        <template
          slot="header"
          slot-scope="scope"
        >
          <div class="text-center w-full">
            WhatsApp
          </div>
        </template>
        <el-table-column
          v-slot="scope"
          label="Authentication - International"
          prop="whatsapp"
          align="left"
          show-overflow-tooltip
          class="ci"
        >
          <div
            v-if="scope.row['WA-CBP-I']"
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['WA-CBP-I'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            -
          </div>
        </el-table-column>
        <el-table-column
          v-slot="scope"
          :label="$t('chat_apps.categories.Authentication')"
          prop="whatsapp"
          align="left"
          show-overflow-tooltip
          class="bi"
        >
          <div
            v-if="scope.row['WA-CBP-A']"
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['WA-CBP-A'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>

        <el-table-column
          v-slot="scope"
          :label="$t('chat_apps.categories.Marketing')"
          prop="whatsapp"
          align="left"
          show-overflow-tooltip
          class="bi"
        >
          <div
            v-if="scope.row['WA-CBP-M'] "
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['WA-CBP-M'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>

        <el-table-column
          v-slot="scope"
          :label="$t('chat_apps.categories.Utility')"
          prop="whatsapp"
          align="left"
          show-overflow-tooltip
          class="bi"
          width="100"
        >
          <div
            v-if="scope.row['WA-CBP-T'] "
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['WA-CBP-T'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>

        <el-table-column
          v-slot="scope"
          :label="$t('column_labels.service')"
          prop="whatsapp"
          align="left"
          show-overflow-tooltip
          class="bi"
          width="100"
        >
          <div
            v-if="scope.row['WA-CBP-S'] "
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['WA-CBP-S'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>
      </el-table-column>


      <!-- Viber text column -->
      <el-table-column
        label="Viber"
        header-align="center"
      >
        <el-table-column
          v-slot="scope"
          label="Text"
          prop="viberText"
          align="left"
          show-overflow-tooltip
          class="vt"
          width="100"
        >
          <div
            v-if="scope.row['VB-OUT-TXT']"
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['VB-OUT-TXT'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>

        <!-- Viber rich media column -->
        <el-table-column
        width="100"
          v-slot="scope"
          label="Media"
          prop="viberRichMedia"
          align="left"
          show-overflow-tooltip
          class="vm"
        >
          <div
            v-if="scope.row['VB-OUT-MEDIA']"
            class="text-xs truncate"
          >
            {{ formatPrice(scope.row['VB-OUT-MEDIA'], scope.row['Currency']) }}
          </div>
          <div
            v-else
            class="text-xs truncate"
          >
            —
          </div>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currency: '€',
    };
  },

  methods: {
    // Format currency price string
    formatPrice(n, currency = 'EUR', l = 'en-US', d = 7) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(n)) return `${currency}0`;

      const formatPrice = Number(n).toLocaleString(l, { maximumFractionDigits: d });
      return `${currency} ${formatPrice}`;
    },

    // Format number e.g: 1000 - 1,000
    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
</script>
<style lang="scss">
  .ca-table {
    thead.is-group th {
      background-color: #525252 !important;
      color: white !important;
      border-bottom: none !important;

      .cell {
        word-break: break-word;
      }
    }

    .el-table_2_column_6_column_7 {
      border-right: none;
    }

    .el-table_2_column_9_column_10 {
      border-right: none;
    }
  }
</style>
