//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    defaultCountry: {
      type: String,
      default: '',
    },

    countries: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      subaccountValue: [],
    };
  },
};
