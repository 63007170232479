//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import ChatTable from './partials/ChatTable.vue';
import SmsTable from './partials/SmsTable.vue';
import Pagination from './partials/Pagination.vue';
import ChatFilter from './partials/ChatFilter.vue';
import SmsFilter from './partials/SmsFilter.vue';
import { getSubscribedCountry } from '../../../common/handle-package-limit';

export default {
  name: 'Pricing',

  components: {
    ChatTable,
    SmsTable,
    Pagination,
    ChatFilter,
    SmsFilter,
  },

  data() {
    return {
      appList: {},

      search: '',

      activeTabName: 'sms', // Show sms pricing by default

      initLoading: true,

      tzCountry: '',
    };
  },

  computed: {
    ...mapGetters({
      loading: 'pricing/getLoading',
      loadingDownload: 'pricing/getDownloadLoading',
      loadingSubaccount: 'user/getLoading',
      filters: 'pricing/getFilters',
      subaccountList: 'user/getSubaccounts',
      smsPricing: 'pricing/getSMSPricing',
      chatPricing: 'pricing/getCAPricing',
      countries: 'pricing/getCountries',
      timezones: 'user/getTimezones',
      pagination: 'pricing/getPagination',
      smsAccess: 'user/getSmsAccess',
      chatAccess: 'user/getChatAppsAccess',
      hasPricingEntitlementAccess: 'pricing/getPricingEntitlementAccess',
      user: 'user/getUser',
    }),
  },

  created() {
    // Set tab name and accessibility
    const self = this;
    this.appList = {
      sms: {
        label: self.$t('sidebar_menu_parent.SMS'),
        disabled: !this.smsAccess,
      },
      chatapps: {
        label: self.$t('sidebar_menu_parent.Chat apps'),
        disabled: !this.chatAccess,
      },
    };


    // Load initial data
    this.loadSelectOptions();
  },

  methods: {
    ...mapMutations({
      setFilters: 'pricing/SET_FILTERS',
      setPagination: 'pricing/SET_PAGINATION',
    }),

    ...mapActions({
      getCAPricing: 'pricing/getCAPricings',
      getSMSPricing: 'pricing/getSMSPricings',
      getStaticPricingDownloadUrl: 'pricing/getStaticPriceListDownloadUrl',
      downloadChatPricing: 'pricing/downloadChatPricing',
      downloadSmsPricing: 'pricing/downloadSmsPricing',
      getSmsSubaccounts: 'user/fetchSMSSubAccounts',
      getChatSubaccounts: 'user/fetchCASubAccounts',
      getCountries: 'pricing/getPricingCountries',
      getTimezones: 'user/getTimezones',
    }),

    isSubscriptionActive() {
      const { Subscription: { Package: { subscriptions = {} } = {} } = {} } = this.user;
      const { status } = subscriptions[0] || {};

      return status === 'ACTIVE';
    },

    async downloadStaticPricing() {
      const url = await this.getStaticPricingDownloadUrl({ accountId: this.user.AccountId });

      const newWindow = window.open('', '_blank');
      newWindow.location.href = url;
    },

    handleTabClick({ name }) {
      let initFilter = {};

      const subAccountId = _.get(this, 'filters.subAccountId', []);
      const subAccountUid = _.get(this, 'subaccountList[0].SubAccountUid');

      let defaultSubaccount;

      switch (name) {
      case 'sms':
        // Set default filters for SMS
        initFilter = {
          ...this.filters,
          country: this.filters.country || this.tzCountry,
          subAccountId: [],
        };

        this.updateFilter(initFilter);
        break;

      case 'chatapps':

        if (subAccountId.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          defaultSubaccount = this.filters.subAccountId[0];
        } else if (subAccountUid) {
          defaultSubaccount = subAccountUid;
        }


        initFilter = {
          ...this.filters,
          country: '',
          subAccountId: defaultSubaccount,
        };

        this.updateFilter(initFilter);
        break;

      default:
        break;
      }
    },

    nextPage() {
      this.setPagination({
        index: this.pagination.index + 1,
      });
    },

    prevPage() {
      this.setPagination({
        index: this.pagination.index - 1,
      });
    },

    // Update filter state
    updateFilter(filter = {}) {
      this.setPagination({
        index: 0,
      });
      this.setFilters({
        filters: {
          ...this.filters,
          ...filter,
        },
      });

      this.getData();
    },

    // Fetch pricing data
    async getData() {
      this.initLoading = true;
      try {
        const payload = this.filters;
        if (this.activeTabName === 'chatapps' && this.chatAccess) {
          await this.getChatSubaccounts();
          if (this.subaccountList.length <= 0) return;
          await this.getCAPricing(payload);
        } else if (this.activeTabName === 'sms' && this.smsAccess) {
          await this.getSmsSubaccounts();
          if (this.subaccountList.length <= 0) return;
          await this.getSMSPricing();
        }

        this.index = 0;
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.initLoading = false;
      }
    },

    async loadSelectOptions() {
      try {
        await this.getCountries();
        await this.getTimezones();

        const tzname = this.$jstz2.determine().name();
        this.tzCountry = this.timezones.find(tz => tz.TimeZoneName === tzname).Country;

        // Set initial filter
        this.updateFilter({
          ...this.filters,
          country: this.tzCountry,
        });
      } catch (err) {
        this.$showError(this, err);
      }
    },

    exportData() {
      switch (this.activeTabName) {
      case 'sms':
        this.downloadSmsPricing()
          .catch((e) => {
            this.$showError(this, e);
          });
        break;
      case 'chatapps':
        this.downloadChatPricing().catch((e) => {
          this.$showError(this, e);
        });
        break;
      default:
        break;
      }
    },

    getPaginatedAppData() {
      let data = [];
      switch (this.activeTabName) {
      case 'sms':
        data = [...this.smsPricing];
        break;
      case 'chatapps':
        data = [...this.chatPricing];
        break;
      default:
        break;
      }

      const {
        index,
        limit,
      } = this.pagination;

      return data.slice((index) * limit, (index + 1) * limit);
    },

    getAppData() {
      let data = [];
      switch (this.activeTabName) {
      case 'sms':
        data = [...this.smsPricing];
        break;
      case 'chatapps':
        data = [...this.chatPricing];
        break;
      default:
        break;
      }

      return data;
    },
  },
};
