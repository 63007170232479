//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currency: '€',
    };
  },

  methods: {
    // Format currency price string
    formatPrice(n, currency = 'EUR', l = 'en-US', d = 7) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(n)) return `${currency}0`;

      const formatPrice = Number(n).toLocaleString(l, { maximumFractionDigits: d });
      return `${currency} ${formatPrice}`;
    },

    // Format number e.g: 1000 - 1,000
    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
