/* eslint-disable no-param-reassign */
import { handlePackageLimit } from '../../../common/handle-package-limit';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    loading: true,
    downloadLoading: false,
    filters: {
      subAccountId: '',
      country: '',
    },
    CAPricing: [],
    SMSPricing: [],
    countries: null,
    pagination: {
      limit: 15,
      index: 0,
    },
    hasPricingEntitlement: false,
  },

  getters: {
    getLoading: st => st.loading,
    getDownloadLoading: st => st.downloadLoading,
    getCountries: st => st.countries,
    getFilters: st => st.filters,
    getCAPricing: (st) => {
      // Return without filter if country is empty
      if (!st.filters.country) return st.CAPricing;
      return st.CAPricing.filter(c => c.Country === st.filters.country);
    },
    getSMSPricing: st => st.SMSPricing,
    getPagination: st => st.pagination,
    getPricingEntitlementAccess: st => st.hasPricingEntitlement,
  },

  mutations: {
    SET_PAGINATION: (st, payload) => { st.pagination = { ...st.pagination, ...payload }; },
    SET_LOADING: (st, payload) => { st.loading = payload.loading; },
    SET_DOWNLOAD_LOADING: (st, payload) => { st.downloadLoading = payload.loading; },
    SET_COUNTRIES: (st, payload) => {
      const hasPricingEntitlement = handlePackageLimit('feature-cp-pricing');

      st.countries = payload.countries;
      st.hasPricingEntitlement = hasPricingEntitlement;
    },
    SET_FILTERS: (st, payload) => { st.filters = payload.filters; },
    SET_PRICING: (st, payload) => {
      if (payload.app === 'chatapp') {
        st.CAPricing = payload.pricing;
      } else if (payload.app === 'sms') {
        st.SMSPricing = payload.pricing;
      }
    },
  },

  actions,
};
