<template>
  <el-table
    v-loading="loading"
    size="small"
    class="w-full table"
    :data="data"
  >
    <!-- Operator column -->
    <el-table-column
      v-slot="scope"
      :label="$t('column_labels.operator')"
      prop="OperatorName"
      show-overflow-tooltip
    >
      <div class="text-xs truncate">
        {{ scope.row.OperatorName }}
      </div>
    </el-table-column>

    <!-- MCC column -->
    <el-table-column
      v-slot="scope"
      :label="$t('column_labels.mcc')"
      prop="MCC"
      show-overflow-tooltip
    >
      <div class="text-xs truncate">
        {{ scope.row.MCC }}
      </div>
    </el-table-column>

    <!-- MNC column -->
    <el-table-column
      v-slot="scope"
      :label="$t('column_labels.mnc')"
      prop="MNC"
      show-overflow-tooltip
    >
      <div class="text-xs truncate">
        {{ scope.row.MNC }}
      </div>
    </el-table-column>

    <!-- SUBACCOUNTS column -->
    <template v-if="data && data.length">
      <el-table-column
        v-for="subaccountId in getViewableSubaccounts().splice(0,3)"
        :key="subaccountId"
        v-slot="scope"
        prop="SubAccounts"
        :label="subaccountId"
        show-overflow-tooltip
      >
        <div
          v-if="scope.row.SubAccounts && scope.row.SubAccounts[subaccountId]"
          class="text-xs truncate"
        >
          {{ `${scope.row.SubAccounts[subaccountId].Currency || ''} ${scope.row.SubAccounts[subaccountId].Price || '—'}` }}
        </div>
        <div
          v-else
          class="text-xs truncate"
        >
          —
        </div>
      </el-table-column>
    </template>

    <!-- Expandable row -->
    <el-table-column
      v-if="Array.isArray(filters.subAccountId) && (filters.subAccountId.length == 0 || filters.subAccountId.length > 3)"
      type="expand"
    >
      <template slot-scope="scope">
        <dl
          v-if="data.length"
          class="data-grid"
        >
          <template
            v-for="subaccountId in getViewableSubaccounts()"
          >
            <dt class="data-grid__title">
              <span class="font-bold">{{ subaccountId }}</span>
            </dt>
            <dd
              v-if="scope.row.SubAccounts && scope.row.SubAccounts[subaccountId]"
              class="data-grid__content"
            >
              {{ `${scope.row.SubAccounts[subaccountId].Currency || ''} ${scope.row.SubAccounts[subaccountId].Price || '—'}` }}
            </dd>
            <dd
              v-else
              class="data-grid__content"
            >
              —
            </dd>
          </template>
        </dl>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { handlePackageLimit } from '../../../../common/handle-package-limit';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      type: Object,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      subaccountsMap: {},
    };
  },

  watch: {
    subaccountList() {
      this.generateSubaccountMap();
    },
  },

  created() {
    this.generateSubaccountMap();
  },

  methods: {
    generateSubaccountMap() {
      // create a subaccount dictionary
      this.subaccountsMap = this.subaccountList.reduce((list, item) => {
        // eslint-disable-next-line no-param-reassign
        list[item.SubAccountUid] = item.SubAccountId;
        return list;
      }, {});
    },

    // returns an array of subaccountId to be shown in the column
    getViewableSubaccounts() {
      if (!this.data.length) return [];

      const { subAccountId } = this.filters;
      let subaccounts = [];

      if (subAccountId.length) {
        subaccounts = subAccountId.map(subacc => this.subaccountsMap[subacc]);
      } else {
        subaccounts = Object.keys(this.data[0].SubAccounts || []);
      }

      return subaccounts;
    },

    showColumn(column) {
      let show = true;
      let user;
      user = localStorage.getItem('CPV3_User') || {};
      user = user && JSON.parse(user);

      if (user.Subscription.Status && handlePackageLimit(column)) {
        show = false;
      }

      return show;
    }
  },
};
</script>

<style scoped lang="scss">
.data-grid {
  &__title {
    position: relative;
    float: left;
    width: 200px;
    padding: 10px;
    clear: left;

    &:nth-of-type(even) {
      background: whitesmoke;
    }
  }

  &__content {
    position: relative;
    float: left;
    width: calc(100% - 200px);
    padding: 10px;

    &:nth-of-type(even) {
      background: whitesmoke;
    }
  }
}
</style>
